import React from "react" 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button} from 'antd';

const Test = () => {
    const [userAgent, setUserAgent] = React.useState('')

    React.useEffect(() => {
        if (typeof window !== "undefined") {    
            setUserAgent(window.navigator.userAgent|| window.navigator.vendor || window.opera)

            /*
            if (isInAppBrowser(window.navigator.userAgent)) {
                openNativeWebOnMobile(window)
            }
            */
        }
    }, [])

    

    return (
        <>
        {getInAppBrowserInfo(userAgent) || 'desktop env'}
        <br/><br/>
        <p style={{color:'blue'}}>
        is inappbrowser : {getInAppBrowserInfo(userAgent) ? 'yes' : 'false'}
        </p>
        is inappbrowser (v2): {isMobileBrowser() ? 'yes' : 'no'} 
        <br/><br/>
        {JSON.stringify(userAgent)}
    
    </> 
    )
}

function isMobileBrowser() {
  // 檢查 user agent 是否包含手機瀏覽器的常見關鍵字
  const mobileKeywords = [
      "Android", "iPhone", "iPad", "iPod", "BlackBerry",
      "Windows Phone", "webOS", "Mobile"
  ];

  if (typeof window === 'undefined') {
    return '.......'
  }

  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;


  // 檢查 user agent 是否包含手機瀏覽器的關鍵字
  for (let keyword of mobileKeywords) {
      if (userAgent.match(new RegExp(keyword, 'i'))) {
          // 排除 Line in-app 瀏覽器
          if (userAgent.match(/Line/i) && userAgent.match(/Line/i).length > 0) {
              return false;
          }
          return true;
      }
  }

  return false;
}

const isInAppBrowser = (userAgent) => {
    let ret = getInAppBrowserInfo(userAgent)

    return !!ret
}

function getInAppBrowserInfo(_ua='') {
    let ua = _ua.toLowerCase().trim();
    const isIOS =
      ua.includes('iphone') || ua.includes('ipod') || ua.includes('ipad');
    const isAndroid = ua.includes('android');
  
    // iOS Chrome
    if (ua.includes('crios')) {
      return 'is_chrome_ios';
    }
  
    // Facebook
    if (ua.includes('fbios') || ua.includes('fb_iab')) {
      return isIOS
        ? 'is_facebook_ios'
        : isAndroid
        ? 'is_facebook_android'
        : 'is_facebook_unknown';
    }
  
    // Instagram
    if (ua.includes('instagram')) {
      return isIOS
        ? 'is_instagram_ios'
        : isAndroid
        ? 'is_instagram_android'
        : 'is_instagram_unknown';
    }
  
    // LINE
    if (ua.includes(' line/')) {
      return isIOS
        ? 'is_line_ios'
        : isAndroid
        ? 'is_line_android'
        : 'is_line_unknown';
    }
  
    // iOS Safari|Twitter|Slack|Discord|etc
    if (isIOS && /safari\/[0-9.]+$/.test(ua)) {
      return 'maybe_safari_ios';
    }
  
    // Android Chrome|Twitter|Slack|Discord|etc
    if (isAndroid && ua.includes('chrome') && /safari\/[0-9.]+$/.test(ua)) {
      return 'maybe_chrome_android';
    }
  
    return null;
  }
  

export default Test
